.playlist-preview {
    & > h1 {
        margin-bottom: calc(var(--spacing) * 2);
    }

    &__header {
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    &__meta-data {
        display: flex;
    }

    &__meta-datum {
        padding-right: calc(var(--spacing) * 5);
        color: var(--brand-dark);

        div {
            font-weight: 600;
            color: var(--brand-primary);
        }
    }

    &__list {
        margin-top: calc(var(--spacing) * 5);
    }

    &__persist {
        flex-grow: 1;
        text-align: right;
        margin-right: 15px;
    }

    &__delete {
        color: var(--brand-danger);
        cursor: pointer;
        width: 25px !important;
        position: absolute;
        top: 32%;
        left: 5px;
    }

    &__draggable {
        position: relative;
    }

    &__wrapper {
        position: relative;
    }

    &__dropArea {
        color: white !important;
        background: white !important;
        position: relative;

        &::before {
            content: " ";
            color: #4C9AFF;
            font-size: 0.5em;
            text-transform: uppercase;
            width: 100%;
            height: 100%;
            border: 2px dashed #4C9AFF;
            border-radius: 3px;
            position: absolute;
            display: flex;
            justify-content: center;
            align-items: center;
            z-index: 1;
        }

        span {
            display: none;
        }
    }
}

.hide {
    display: none;
}
