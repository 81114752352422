.cue {
  // & + & {
  //   margin-top: calc(var(--spacing) * 4);
  // }

  &__error {
    margin-top: calc(var(--spacing) * 3);
    margin-bottom: calc(var(--spacing) * 1);
  }

  &__details {
    display: flex;
    align-items: center;
    padding: calc(var(--spacing) * 1);
  }


  &__data {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
  }

  &__key {
    box-sizing: border-box;
    flex: 0 0 50%;
    padding-right: var(--spacing);
  }

  &__type {
    box-sizing: border-box;
    flex: 0 0 50%;
  }

  &__value {
    flex: 0 0 100%;
    margin-top: calc(var(--spacing) * 1);
  }

  &__action {
    display: flex;
    justify-content: center;
    flex: 0 0 30px;
    margin-left: calc(var(--spacing) * 2);
    margin-top: calc(var(--spacing) * 7.5);
  }

  &__delete {
    padding: calc((var(--spacing)));
    svg {
      display: block;
      cursor: pointer;
      fill: var(--brand-danger);
    }

    &--forbidden {
      svg {
        cursor: not-allowed;
        fill: var(--brand-medium);
      }
    }
  }

  @media (min-width: 768px) {
    // & + & {
    //   margin-top: calc(var(--spacing) * 2);
    // }

    &__data {
      flex-wrap: nowrap;
    }

    // &__key {
    //   flex: 0 0 240%;
    //   padding-right: var(--spacing);
    // }

    &__type {
      flex: 0 0 30%;
      display: flex;
      align-items: center;
      font-weight: 500;
      padding-right: var(--spacing);
    }

    &__value {
      flex: 1 1 auto;
      margin-top: 0;
    }
  }
}
