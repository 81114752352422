.file {
  list-style: none;
  background: white;
  border-radius: var(--border-radius);
  margin-bottom: calc(var(--spacing) * 2);

  &--is-open .file {
    &__header {
      background: var(--brand-primary);
      color: var(--brand-white);
      border-top-left-radius: var(--border-radius);
      border-top-right-radius: var(--border-radius);
    }
  }

  &--is-selected {
    outline: 2px solid var(--brand-highlight);
  }

  &__header {
    display: flex;
    justify-content: center;
    align-items: stretch;
    cursor: pointer;
    user-select: none;
  }

  &__data {
    padding: calc(var(--spacing) * 2) calc(var(--spacing) * 3);
    padding-left: 0;
  }

  &__artist {
    font-size: var(--font-size-small);
  }

  &__player {
    display: flex;
    align-items: center;
    margin-left: auto;
    pointer-events: unset;
    padding: calc(var(--spacing) * 2) calc(var(--spacing) * 3);
  }

  &__time {
    color: var(--brand-light);
    padding-right: calc(var(--spacing) * 2);
  }

  &__play {
    line-height: 0;
  }

  &__body {
    padding: calc(var(--spacing) * 3);
  }

  &__base,
  &__cues {
    margin-bottom: calc(var(--spacing) * 5);
  }

  &__delete {
    margin-top: calc(var(--spacing) * 5);
  }

  @media (min-width: 500px) {
    &__base {
      display: flex;
    }

    &__delete {
      margin-top: 0;
      margin-left: auto;
      padding-left: calc(var(--spacing) * 3);
    }

    &__core-data {
      flex-grow: 1;
    }
  }
}
