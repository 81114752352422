.playlists {
  &__header {
    padding-top: calc(var(--spacing) * 10);
    padding-bottom: calc(var(--spacing) * 5);

    & > * {
      margin-bottom: calc(var(--spacing) * 2);
    }
  }

  &__footer {
    margin: calc(var(--spacing) * 8) 0;
    color: var(--brand-medium);
    font-weight: light;
    text-align: center;
  }

  @media (min-width: 768px) {
    &__header {
      display: grid;
      grid-template-columns: 100px 1fr auto;
      grid-column-gap: var(--spacing);

      & > * {
        margin-bottom: 0;
      }
    }

    &__add {
      margin-left: 30px;
      margin-bottom: 2px;
      align-self: end;
    }
  }
}
