.files {
  &__header {
    padding-top: calc(var(--spacing) * 10);
    // padding-bottom: calc(var(--spacing) * 5);
    background: var(--brand-background);

    & > * {
      margin-bottom: calc(var(--spacing) * 2);
    }
  }

  &__sticky-header {
    position: sticky;
    top: 50px;
    height: 48px;
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    margin: 0 -2px 5px;
    padding: calc(var(--spacing) * 2) calc(var(--spacing) * 1);
    background: var(--brand-background);
    box-shadow: 0 3px 5px var(--brand-background);
    z-index: 8;
  }

  &__select-all {
    display: flex;
    align-items: flex-end;
    
    &-toggle {
      margin-left: 12px;
      padding-right: calc(var(--spacing) * 2);
      cursor: pointer;
    }
  }

  &__footer {
    margin: calc(var(--spacing) * 8) 0;
    color: var(--brand-medium);
    font-weight: light;
    text-align: center;
  }

  @media (min-width: 768px) {
    &__header {
      display: grid;
      grid-template-columns: 100px 1fr 150px auto;
      grid-column-gap: var(--spacing);

      & > * {
        margin-bottom: 0;
      }
    }

    &__add {
      margin-left: 30px;
      margin-bottom: 2px;
      align-self: end;
    }
  }
}
