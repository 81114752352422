.add-files {
  &__dropzone {
    height: 300px;
    position: relative;
  }

  &__drop-info {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: var(--brand-medium);
  }

  &__info {
    margin-left: 0 !important;
    margin-right: auto;
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    color: var(--brand-medium);
}

  &__uploads {
    margin-top: calc(var(--spacing) * 4);
  }

  &__upload {
    & + & {
      margin-top: calc(var(--spacing) * 3);
    }

    &-bar {
      min-width: 1%;
      height: 10px;
      border-radius: var(--border-radius);
      background: var(--brand-primary);
      transition: width 0.5s;
    }

    &-name {
      font-size: var(--font-size-small);
      margin-top: var(--spacing);
      color: var(--brand-medium);
    }
  }
}
