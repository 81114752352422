.playlist-file {
  display: grid;
  grid-template-rows: repeat(2, 1fr);
  grid-template-columns: auto 80px 120px;
  gap: calc(var(--spacing) * 2);
  list-style: none;
  background: white;
  border-radius: var(--border-radius);
  margin-bottom: calc(var(--spacing) * 2);
  padding: calc(var(--spacing) * 2) calc(var(--spacing) * 3);
  color: inherit;
  cursor: pointer;
  
  &:hover,
  &:visited {
    text-decoration: none;
    color: inherit;
  }

  &__artist {
    font-size: var(--font-size-small);
    margin-left: 15px;
  }

  &__cue {
    width: auto !important;
    justify-self: start;
    align-self: center;
    font-size: var(--font-size-small);
    background: var(--brand-lighter);
    border-radius: var(--border-radius);
    padding: calc(var(--spacing) * 0.5) calc(var(--spacing) * 1);

    &--unconfigured {
      background: var(--brand-highlight);
    }
  }

  &__title {
    margin-left: 15px;
  }

  &__player {
    display: flex;
    align-items: center;
    margin-left: auto;
    pointer-events: unset;
    padding-left: calc(var(--spacing) * 3);
  }

  &__time {
    color: var(--brand-light);
    padding-right: calc(var(--spacing) * 2);
  }

  &__play {
    cursor: pointer;
    line-height: 0;
  }

  @media (min-width: 500px) {
    grid-template-rows: none;
    grid-template-columns: minmax(1fr, auto) 1fr 120px;
    align-items: center;
  }
}