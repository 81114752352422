.file-editor-info {
    margin-left: 0 !important;
    margin-right: auto;
    font-size: var(--font-size-small);
    line-height: var(--line-height-small);
    color: var(--brand-medium);
}

.file-editor {
    &__loader {
        display: flex;
        justify-content: center;
        margin: calc(var(--spacing) * 8) 0;
    }

    &__error {
        margin-bottom: calc(var(--spacing) * 2);
    }
}