.add-meta-data {
  display: flex;
  align-items: center;
  margin-top: calc(var(--spacing) * 2);

  svg {
    cursor: pointer;
    fill: var(--brand-secondary);
    margin-right: calc(var(--spacing) * 1);
  }

}