.file-selection {
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 20px;

  flex: 0 0 50px;

  &::before {
    content: '';
    display: block;
    width: 15px;
    height: 15px;
    border: 2px solid var(--brand-primary);
    border-radius: 100%;
  }

  &--is-open {
    &::before {
      border: 2px solid var(--brand-white);
    }
  }

  &--is-selected {
    &::after {
      position: absolute;
      content: '';
      display: block;
      width: 10px;
      height: 10px;
      border-radius: 100%;
      background: var(--brand-highlight);
    }
  }
}