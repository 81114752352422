.playlist-list-item {
  display: block;
  list-style: none;
  background: white;
  border-radius: var(--border-radius);
  margin-bottom: calc(var(--spacing) * 2);
  text-decoration: none;

  &:hover {
    text-decoration: none;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: calc(var(--spacing) * 2) calc(var(--spacing) * 3);
    cursor: pointer;
    user-select: none;
  }

  &__name {
    color: var(--brand-primary);

    &:hover {
      color: var(--brand-primary);
    }
  }

  &__type {
    color: var(--brand-light);
  }

  &__delete {
    padding: calc((var(--spacing)));

    svg {
      display: block;
      cursor: pointer;
      fill: var(--brand-danger);
    }
  }

  @media (min-width: 500px) {
    &__base {
      display: flex;
    }

    &__delete {
      margin-top: 0;
      margin-left: auto;
      padding-left: calc(var(--spacing) * 3);
    }

    &__core-data {
      flex-grow: 1;
    }
  }
}