.playlist-restriction {
    margin-top: calc(var(--spacing) * 2);
    margin-bottom: calc(var(--spacing) * 3);
    display: grid;
    grid-gap: calc(var(--spacing) * 1);
    grid-template-columns: 5fr 4fr 4fr 20px;
    box-sizing: border-box;

    &--add {
        grid-template-columns: 5fr 4fr 4fr;
    }

    &>*:nth-of-type(1) {
        grid-column: span 3;
    }

    &__label {
        font-size: var(--font-size-small);
    }

    &__delete {
        margin-top: calc((var(--spacing)) * 6);
        padding: calc((var(--spacing)));
        padding-right: calc((var(--spacing)) * 2);

        svg {
            display: block;
            cursor: pointer;
            fill: var(--brand-danger);
        }
    }

    &__field {
        font-size: var(--font-size-small);
        color: var(--brand-light);

        &--before {
            padding-left: calc((var(--spacing)));
        }

        &--after {
            padding-right: calc((var(--spacing)));
        }
    }
}