.core-data {
  display: grid;
  box-sizing: border-box;
  grid-template-columns: 1fr 1fr;
  column-gap: 1rem;
  row-gap: 1rem;

  @media (min-width: 500px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @media (min-width: 768px) {
    grid-template-columns: repeat(4, 1fr);
  }
}

.core-datum {
  box-sizing: border-box;
  word-break: break-all;
  padding-right: calc(var(--spacing) * 2);

  &__label {
    font-weight: 500;
    color: var(--brand-darker);
  }

  &__value {
    font-weight: 300;
    color: var(--brand-dark);
  }
}
