.search-help {
  &__cases {
    margin: calc(var(--spacing) * 5) 0;
  }
  &__code {
    font-size: var(--font-size-small);
    margin-bottom: calc(var(--spacing));
  }

  &__case {
    margin-bottom: calc(var(--spacing) * 5);
  }

  @media (min-width: 768px) {
    &__case {
      display: flex;
      margin-bottom: calc(var(--spacing) * 2);
    }

    &__code {
      flex: 0 0 30%;
      padding-right: calc(var(--spacing) * 5);
    }
  }
}
