.playlist-settings {
    &__error {
        margin-bottom: calc(var(--spacing) * 2);
    }

    &__label {
        display: flex;
        justify-content: space-between;

        &-loader {
            margin-right: var(--spacing);
        }
    }

    padding-bottom: 100px;
}