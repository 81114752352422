.cues {
  &__list {
    margin-top: calc(var(--spacing) * 2);
    padding: 0;
  }

  &__info {
    margin-top: -5px;
    font-size: var(--font-size-small);
    color: var(--brand-medium);
    padding-bottom: calc(var(--spacing) * 3);
    padding-top: calc(var(--spacing) * 3);
  }

  &cues__addKey{
    display: flex;
    align-items: center; 
    svg {
      cursor: pointer;
      fill: var(--brand-secondary);
      margin-right: calc(var(--spacing) * 1);
    }
  }

  &__add {
    display: flex;
    align-items: center;
    margin-top: calc(var(--spacing) * 2);
    svg {
      cursor: pointer;
      fill: var(--brand-secondary);
      margin-right: calc(var(--spacing) * 1);
    }
  }

  &__action {
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 0 0 30px;
    margin-left: calc(var(--spacing) * 2);
    margin-right: var(--spacing);
  }

  &__delete {
    padding: calc((var(--spacing)));
    svg {
      display: block;
      cursor: pointer;
      fill: var(--brand-danger);
    }

    &--forbidden {
      svg {
        cursor: not-allowed;
        fill: var(--brand-medium);
      }
    }
  }
}

.player_meta_data {
  box-sizing: border-box;

  &__artist,
  &__title {
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    color: var(--brand-dark)
  }
  &__artist {
    font-size: 12px;
  }
  &__title {
      font-size: 20px;
      
  }
}
