.meta-datum {
  &+& {
    margin-top: calc(var(--spacing) * 4);
  }

  &__error {
    margin-top: calc(var(--spacing) * 3);
    margin-bottom: calc(var(--spacing) * 1);
  }

  &__details {
    display: flex;
    align-items: center;
  }

  &__data {
    display: flex;
    flex-wrap: wrap;
    flex: 1 1 auto;
  }

  &__key {
    box-sizing: border-box;
    flex: 0 0 50%;
    padding-right: var(--spacing);
  }

  &__type {
    box-sizing: border-box;
    flex: 0 0 50%;
  }

  &__value {
    flex: 0 0 100%;
    margin-top: calc(var(--spacing) * 1);
    align-self: center;
  }

  &__value-text {
    font-size: var(--font-size-small);
    color: var(--brand-light);
    text-align: center;
  }

  &__additional-values {
    margin-top: calc(var(--spacing) * 0.5);
    font-size: var(--font-size-small);
    color: var(--brand-medium);

    span {
      cursor: pointer;
      color: var(--brand-secondary);
    }
  }

  &__action {
    display: flex;
    align-self: flex-start;
    align-items: center;
    justify-content: center;
    flex: 0 0 30px;
    margin-top: calc(var(--spacing) * 1.5);
    margin-left: calc(var(--spacing) * 2);
  }

  &__delete {
    padding: calc((var(--spacing)));

    svg {
      display: block;
      cursor: pointer;
      fill: var(--brand-danger);
    }

    &--forbidden {
      svg {
        cursor: not-allowed;
        fill: var(--brand-medium);
      }
    }
  }

  @media (min-width: 768px) {
    &+& {
      margin-top: calc(var(--spacing) * 2);
    }

    &__data {
      flex-wrap: nowrap;
    }

    &__key {
      flex: 0 0 20%;
      padding-right: var(--spacing);
    }

    &__type {
      flex: 0 0 15%;
      padding-right: var(--spacing);
    }

    &__value {
      flex: 1 1 auto;
      margin-top: 0;
    }
  }
}