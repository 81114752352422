.playlist-detail {
  padding-top: calc(var(--spacing) * 3);

  &__preview {
    border-top: thin solid var(--brand-lighter);
    padding-top: calc(var(--spacing) * 5);
    margin-top: calc(var(--spacing) * 5);
  }


  @media (min-width: 1024px) {
    display: flex;
    align-items: flex-start;
    height: calc(100vh - 120px);

    &__preview {
      order: 1;
      flex: 1;
      padding-top: 0;
      margin-top: 0;
      border-top: none;
    }
    
    &__settings {
      order: 2;
      flex: 0 0 375px;
      padding-left: calc(var(--spacing) * 5);
      margin-left: calc(var(--spacing) * 5);
      border-left: thin solid var(--brand-lighter);
    }
  }
}