:root {
  --font-family-regular: Arial, sans-serif;
  --font-size: 1rem;
  --font-size-paragraph: 1.25rem;
  --font-size-large: 1.25rem;
  --font-size-medium: 1rem;
  --font-size-small: 0.75rem;
  --font-size-xtra-small: 0.6rem;
  --font-size-legal: 0.5rem;
  --font-size-h1: 2.75rem;
  --font-size-h2: 2.25rem;
  --font-size-h3: 2rem;
  --font-size-h4: 1.5rem;

  --line-height: 1.5rem;
  --line-height-large: 1.25rem;
  --line-height-medium: 1rem;
  --line-height-small: 0.9rem;
  --line-height-legal: 0.75rem;
  --line-height-paragraph: 1.8rem;
  --line-height-h1: 2.6rem;
  --line-height-h2: 2.3rem;
  --line-height-h3: 2rem;
  --line-height-h4: 1.8rem;

  --brand-primary: #273b58;
  --brand-secondary: rgb(0, 82, 204);
  --brand-danger: rgb(222, 53, 11);
  --brand-highlight: rgb(153, 141, 217);
  
  --brand-black: #0d0d0d;
  --brand-darkest: #333333;
  --brand-darker: #4d4d4d;
  --brand-dark: #808080;
  --brand-medium: #a2a2a2;
  --brand-light: #bfbfbf;
  --brand-lighter: #d9d9d9;
  --brand-lightest: #f2f2f2;
  --brand-white: #ffffff;
  --brand-background: var(--brand-lightest);

  --nav-height: 50px;
  --spacing: 5px;
  --border-radius: 2px;
  --page-padding: 20px;
  --header-height: 50px;
}
