.playlist-additive {
    margin-top: calc(var(--spacing) * 2);
    margin-bottom: calc(var(--spacing) * 3);
    display: grid;
    grid-gap: calc(var(--spacing) * 1);
    grid-template-columns: 1fr 1fr 20px;
    box-sizing: border-box;

    &--add {
        grid-template-columns: 1fr 1fr;
    }

    &>*:nth-last-of-type(1) {
        grid-column: span 2;
    }

    &__label {
        font-size: var(--font-size-small);
    }

    &__delete {
        margin-top: calc((var(--spacing)) * 6);
        padding: calc((var(--spacing)));
        padding-right: calc((var(--spacing)) * 2);

        svg {
            display: block;
            cursor: pointer;
            fill: var(--brand-danger);
        }
    }
}